import { Center, Heading, Stack, Flex, Spacer, Button, Box, Grid, GridItem, Spinner } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import TimeTable from './TimeTable';
import getSchedule from '../APIUtils/getSchedule';
import getPlayerSchedule from '../APIUtils/getPlayerSchedule';
import PlayerSchedule from './PlayerSchedule';

function Schedule({setAllUsersAdded}) {

    const [isLoading, setIsLoading] = useState(true)

    // const [players, setPlayers] = useState([
    //     {name: 'Dabby', areaId: 'eskme-14-tableviewcityofcapetownwesterncape'},
    //     {name: 'Mystic', areaId: 'tshwane-8-queenswood'},
    //     {name: 'Mint', areaId: 'eskde-11-worcestermunicbreedevalleywesterncape'},
    //     {name: 'Strange', areaId: 'jhbcitypower3-11-strubensvalley'},
    //     {name: 'Gath', areaId: 'capetown-14-sunningdale'}
    // ])

    const [players, setPlayers] = useState([])

    const [playerSchedule, setPlayerSchedule] = useState()

    useEffect(() => {
        setIsLoading(true);

        const fetchData = async () => {
            try {

                const playerStorage = window.localStorage.getItem("Player Schedule");
                
                if (!playerStorage) {
                    const userStorage = window.localStorage.getItem("Users");
                    const parsedUsers = JSON.parse(userStorage)
                    const updatedPlayers = await Promise.all(parsedUsers.map(async (player) => {
                        const data = await getPlayerSchedule(player.areaId);
                        return { ...player, data };
                    }));
    
                    window.localStorage.setItem("Player Schedule", JSON.stringify(updatedPlayers));
                    setPlayerSchedule(updatedPlayers);
                } else {
                    setPlayerSchedule(JSON.parse(playerStorage));
                }
                setIsLoading(false);
            } catch (error) {
                console.error('Error updating or fetching player schedules:', error);
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    if (isLoading) {
        return (<Spinner />)
      }

   else return (
    <Box pt={5}>
        
        {/* <Heading m="5">Available times</Heading>
        <TimeTable eskomStage={eskomStage} playerSchedule={playerSchedule} /> */}
        <Heading m="5">Players Schedules</Heading>
        <Grid m="5"
                templateColumns='repeat(5, 1fr)'
                gap={5}>
            {playerSchedule && playerSchedule.length !== 0 ?
            playerSchedule.map((player, index) => (
                <GridItem key={index} rowSpan={1} colSpan={{lg: 1, base: 5, md: 5, sm: 5}}>
                <PlayerSchedule player={player} />
                </GridItem>
            ))
                :
                <Button onClick={() => setAllUsersAdded(false)}>Add Users</Button>
            }
        </Grid>
    </Box>
  );
}

export default Schedule;