import { Box, Text } from '@chakra-ui/react';

const NewScheduleBox = ({ day, player }) => {

    function getTimeFromTimestamp(timestampString) {
        const dateObj = new Date(timestampString);
        
        // Extract hours and minutes from the date object
        const hours = dateObj.getHours().toString().padStart(2, '0');
        const minutes = dateObj.getMinutes().toString().padStart(2, '0');
        
        // Return the time string in "HH:mm" format
        return `${hours}:${minutes}`;
    }

    function getDateFromTimestamp(timestampString) {
        return timestampString.substring(0, 10); // Extracts the date portion (YYYY-MM-DD)
    }

    let combinedTimesSet = new Set();

    // Add cStageTime to the combinedTimesSet
    player.data.events.forEach(event => {
        const nextEventStart = event.start
        const startDate = getDateFromTimestamp(nextEventStart);
        const startTime = getTimeFromTimestamp(nextEventStart);

        const nextEventEnd = event.end
        const endTime = getTimeFromTimestamp(nextEventEnd);

        let eventTime = startTime + " - " + endTime;
        if (startDate === day.date) {
            combinedTimesSet.add(eventTime);
        }
    });

    // Convert the Set back to an array
    let combinedTimes = Array.from(combinedTimesSet);

    return (
        <Box m="4">
            <Text>{day.name}</Text>
            {combinedTimes.map((time, index) => (
                <Text key={index}>{time}</Text>
            ))}
        </Box>
    );
};

export default NewScheduleBox;