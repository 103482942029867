import { Box, Text } from "@chakra-ui/react";

function DateTimeConverter({nextStage}) {
  const timestamp = nextStage;
  const dateObject = new Date(timestamp);

  const timeOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: false // Adjust this to false if you want 24-hour format
  };

  // Extracting date and time components
  const date = dateObject.toLocaleDateString(); // Format the date part
  const time = dateObject.toLocaleTimeString([], timeOptions); // Format the time part

  return (
      <Box>
          <Text>Date: {date}</Text>
          <Text>Time: {time}</Text>
      </Box>
  );
}

export default DateTimeConverter;