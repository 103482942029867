const getStatus = async () => {

    const myHeaders = new Headers();
    myHeaders.append("token", "7F94CB54-91CB4DC6-8F7DB784-E96B37E9");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };
    

    const res = await fetch("https://corsproxy.io/?https%3A%2F%2Fus-central1-sepush-app.cloudfunctions.net%2Fsepush-serverless-business-api-staging-http%2Fbusiness%2F2.0%2Fstatus", requestOptions)

    const status = await res.json()

    return status
}

export default getStatus