import { Heading, Stack, Flex, Text, Box, Spacer, Img, Center, Link, Button, FormControl, FormLabel, Switch } from '@chakra-ui/react';
import esp from '../Images/esp.jpeg'
import int from '../Images/int.png'
import { MoonIcon, SunIcon } from "@chakra-ui/icons";

function Footer({colorMode, toggleColorMode}) {

  return (
    <Flex
    as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      w="100%"
    zIndex="200"
    boxShadow="xl"
    borderRadius="5px"
    bgColor="blackAlpha.300"
    p="4">
          <Center justify="start">
                <Text>Powered by </Text>
                <Text ml="2"><Link href="https://esp.info/"> EskomSePush </Link></Text>
                <Img m="2" h="10" src={esp} />
          </Center>
          <Spacer />
          <Box justify="center">
          <Flex align="center">
            <Text>Colour mode: </Text>
            <Switch
                isChecked={colorMode === "dark"}
                onChange={toggleColorMode}
                colorScheme="teal"
                size="md"
                ml="2"
            />
            {colorMode === "light" ? <SunIcon ml={2} /> : <MoonIcon ml={2} />}
            </Flex>
          </Box>
          <Spacer />
          <Center justify="flex-end">
                <Text>Created by RandyMedia</Text>
          </Center>
    </Flex>
  );
}

export default Footer;