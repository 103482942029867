import { Box, Text, Flex, Heading } from '@chakra-ui/react';
import AllTimes from './AllTimesModal';
import EventBox from './EventBox';

const PlayerSchedule = ({ player }) => {
    
    return (
        <>
            <Heading>{player.name}</Heading>
            <AllTimes player={player} />
            <Flex p="3" flexDir={{sm: "row", base: "column", lg: "column"}}>
            {/* {player.data.schedule.days.map((day, i) => (
                i < 3 ?
                    <ScheduleBox key={i} day={day} player={player} />
                    :
                    null
                    ))} */}
            {player.data.schedule.days.map((day, i) => (
                i < 3 ?
                    <EventBox key={i} day={day} player={player} />
                    :
                    null
                    ))}
            </Flex>
        </>
    );
};

export default PlayerSchedule;