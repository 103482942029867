import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Heading, Menu, MenuItem, MenuList, MenuButton, Flex, Avatar, Image, Box, IconButton, Button, Stack, Center } from "@chakra-ui/react";
import { HamburgerIcon, RepeatIcon } from '@chakra-ui/icons'
import logo from '../Images/int.png';
import CountdownTimer from "./CountdownTimer";

function Navbar() {

    const navigate = useNavigate();

    function refreshPage() {
        localStorage.removeItem("Stage");
        localStorage.removeItem("Player Schedule");
        window.location.reload();
    }

    function clearData() {
        localStorage.removeItem("Stage");
        localStorage.removeItem("Player Schedule");
        localStorage.removeItem("Users");
        window.location.reload();
    }
    const [isActive, setIsActive] = useState(true)

  return (
    <Box mb={5}>
    <Flex
    p='5'
    zIndex="200"
    minW="100%"
    transition='0.1s'
    justifyContent="space-between"
    alignItems="center"
    flexDir={{base: 'column', lg: 'row'}}
    >
        <Flex>
            
        <Stack>
        <Heading>Loadshedding tracker</Heading>
            </Stack>
        </Flex>

        <Flex justifySelf="center">
        <Stack textAlign="center" alignItems="center">
        <Heading>Introversion</Heading>
        <Heading>Esports</Heading>
        </Stack>
        </Flex>
        
        <Flex align="center" justifySelf="flex-end">
            <Heading mr="5">Hi, User!</Heading>
            <Menu>
                <MenuButton m={3}
                            as={IconButton}
                            aria-label='Options'
                            icon={<HamburgerIcon />}
                            variant='outline'/>
                <MenuList>
                    <MenuItem onClick={() => refreshPage()}>Refresh Page</MenuItem>
                    <MenuItem onClick={() => clearData()}>Clear Player Data</MenuItem>
                </MenuList>
            </Menu>
        </Flex>
    </Flex>
    </Box>
  );
}

export default Navbar;