import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Input,
    Box,
    Center,
    Stack,
    Button,
    Text,
    Flex,
    InputRightElement,
    InputGroup,
    Select
  } from '@chakra-ui/react'
import { useEffect, useState } from 'react';
import getArea from '../APIUtils/getArea';

function SearchUsers({setUsers, users}) {

    const [isLoading, setIsLoading] = useState(false)

    const [user, setUser] = useState({
      name: "",
      area: "",
      areaId: ""
    })

    function formChange(e) {
      setUser({ ...user, [e.target.id]: e.target.value});
    }

    const [areas, setAreas] = useState([])

    function addUser() {
      setUsers([ ...users, user])
      setUser({ name: "", area: "", areaId: "" });
      setAreas([])
    }

    const searchArea = async (area) => {
      setIsLoading(true);
      try {
          const data = await getArea(area);
          setAreas(data.areas);
      } catch (error) {
          console.error('Error fetching area:', error);
          // Handle error
      } finally {
          setIsLoading(false);
      }
    };

    const handleSearch = () => {
      const areaValue = user.area
      searchArea(areaValue);
    };

    useEffect(() => {
      console.log(areas)
    },[areas])

    return (
    <Center minH="60vh">
        <form>
      <Stack w="100%">
        <FormControl isRequired>
            <FormLabel>User</FormLabel>
                <Input value={user.name} onChange={(e) => formChange(e)} id="name" type='text' />
            <FormHelperText>Add username</FormHelperText>
        </FormControl>
        <FormControl isRequired>
        <FormLabel>Area</FormLabel>
        <InputGroup>
                <Input value={user.area} onChange={(e) => formChange(e)} id="area" type='text' />
                <InputRightElement width='4.5rem'>
                  <Button onClick={handleSearch}>Search</Button>
                </InputRightElement>
            </InputGroup>
            <FormHelperText>Search Area</FormHelperText>
        </FormControl>
        {areas.length !== 0 ?
          <FormControl isRequired>
          <FormLabel>Select Area</FormLabel>
          <Select placeholder="---" onChange={(e) => formChange(e)} id="areaId">
          {areas && areas.map(area =>
            <option value={area.id}>{area.name}</option>
          )}
          </Select>
        </FormControl>
          :
          null
        }
      {user.name !== "" && user.areaId !== "" ?
      <Button onClick={addUser}>Save user</Button>
      :
      null
      }
      </Stack>
      </form>
      </Center>
    );
  }
  
  export default SearchUsers;