import { Center, Text, Spinner, useColorMode } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import getStatus from '../APIUtils/getStatus';
import Footer from '../Components/Footer';
import Banner from '../Components/Banner';
import AddUsers from './AddUsers';
import Home from './Home';

function Layout() {

  const [currentStage, setCurrentStage] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [allUsersAdded, setAllUsersAdded] = useState(false)
  const { colorMode, toggleColorMode } = useColorMode()

  const [users, setUsers] = useState([])

    useEffect(() => {
        setIsLoading(true);
        const item = window.localStorage.getItem("Stage");

        if (!item) {
            getStatus()
                .then(data => {
                    window.localStorage.setItem("Stage", JSON.stringify(data));
                    setCurrentStage(data); // Update currentStage state
                    setIsLoading(false);
                })
                .catch(error => {
                    console.error('Error fetching status:', error);
                    setIsLoading(false);
                });
        } else {
            setCurrentStage(JSON.parse(item));
            setIsLoading(false);
        }

        const userStorage = window.localStorage.getItem("Users");

        if (userStorage !== null) {
          if (userStorage.length === 0) {
            setAllUsersAdded(false);
          } else {
            setAllUsersAdded(true);
          }
        } else {
          setAllUsersAdded(false);
        }
  }, []);
  
  if (isLoading) {
    return (
    <Center>
    <Spinner
      thickness='8px'
      speed='0.65s'
      emptyColor='gray.200'
      color='blue.500'
      size='xl'/>
    <Text>Loading current stage...</Text>
    </Center>
    )
  }

  else return (
    <>
        <Banner />
        {allUsersAdded ? 
        <Home setAllUsersAdded={setAllUsersAdded} currentStage={currentStage} />
        :
        <AddUsers setAllUsersAdded={setAllUsersAdded} users={users} setUsers={setUsers} />
        }
        <Footer toggleColorMode={toggleColorMode} colorMode={colorMode } />
    </>
  );
}

export default Layout;