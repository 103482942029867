import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Box,
  Text,
  Heading,
  Flex,
  Grid
} from '@chakra-ui/react'

function AllTimes({player, customTheme}) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
      <>
        <Button onClick={onOpen}>Full Schedule</Button>
  
        <Modal size="xl" isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{player.name} Full Week Schedule</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {player.data.schedule.days.map(day =>
                <Box mb="3" key={day}>
                  <Heading size="md">{day.name}</Heading>
                  <Grid templateColumns='repeat(4, 1fr)' gap={1}>
                  {day.stages.map((stage, i) =>
                    <Box key={i} border="2px" borderColor={i + 1} p="1">
                    <Text>Stage {i + 1}</Text>
                    {stage.map(time =>
                      <Text key={time}>{time}</Text>
                    )}
                    </Box>
                    )}
                  </Grid>
                </Box>
                )}
            </ModalBody>
  
            <ModalFooter>
              <Button colorScheme='blue' mr={3} onClick={onClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
  }

  export default AllTimes;