import { Center, Heading, Stack, Flex, Spinner, Text, Box } from '@chakra-ui/react';
import { Fragment, useEffect, useState } from 'react';
import RemainingTime from './RemainingTime';

function TimeTable({playerSchedule}) {

    const [isLoading, setIsLoading] = useState(false)

    const [combineTimeByDay, setCombineTimeByDay] = useState();

    function getTimeFromTimestamp(timestampString) {
        const dateObj = new Date(timestampString);
        
        // Extract hours and minutes from the date object
        const hours = dateObj.getHours().toString().padStart(2, '0');
        const minutes = dateObj.getMinutes().toString().padStart(2, '0');
        
        // Return the time string in "HH:mm" format
        return `${hours}:${minutes}`;
    }

    function getDateFromTimestamp(timestampString) {
        return timestampString.substring(0, 10); // Extracts the date portion (YYYY-MM-DD)
    }

    useEffect(() => {
        setCombineTimeByDay(prevState => {
            let updatedCombineTimeByDay = { ...prevState }; // Copy the current state
    
            playerSchedule.forEach(player => {
                player.data.schedule.days.forEach((day, i) => {
                    if (i < 3) {
                        // Initialize a set to track unique time ranges for each day
                        const uniqueTimesSet = new Set(updatedCombineTimeByDay[day.name] || []);
    
                        player.data.events.forEach(event => {
                            const nextEventStart = event.start;
                            const startDate = getDateFromTimestamp(nextEventStart);
                            const startTime = getTimeFromTimestamp(nextEventStart);
    
                            const nextEventEnd = event.end;
                            const endTime = getTimeFromTimestamp(nextEventEnd);
    
                            let eventTime = startTime + " - " + endTime;
    
                            if (startDate === day.date) {
                                // Add the new event time to the set of unique times for this day
                                uniqueTimesSet.add(eventTime);
                            }
                        });
    
                        // Update the state with the new set of unique times for this day
                        updatedCombineTimeByDay[day.name] = Array.from(uniqueTimesSet);
                    }
                });
            });
    
            return updatedCombineTimeByDay; // Set the updated state
        });
    }, [playerSchedule]);

    function displayRemainingTimes(dayTimes) {
        const startOfDay = '00:00';
        const endOfDay = '24:00';
    
        let remainingTimes = [`${startOfDay} - ${endOfDay}`]; // Initialize with the full day
    
        console.log(remainingTimes);
    }

    if (isLoading) {
        return (<Spinner />)
      }

  else return (
    <>
    <Flex flexDir="row">
        {isLoading && <Spinner />}
    </Flex>
    </>
  );
}

export default TimeTable;