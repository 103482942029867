import {
    IconButton,
    Center,
    Flex,
    Stack,
    Text,
    Heading
  } from '@chakra-ui/react'
  import { SmallCloseIcon } from "@chakra-ui/icons";

function DisplayUsers({users, setUsers}) {

      const removeUser = (i) => {
        const updatedUsers = [...users];
        updatedUsers.splice(i, 1); // Remove the user at the specified index
        setUsers(updatedUsers);
    }

    return (
    <Center>
        <form>
      <Stack w="100%">
        <Heading>Users:</Heading>
        {users.map((user, i) =>
        <Center key={i}>
            <IconButton onClick={() => removeUser(i)} aria-label='Remove User' icon={<SmallCloseIcon />} m="3" />
            <Text >{user.name}, {user.areaId}</Text>
        </Center>
        )}
      </Stack>
      </form>
      </Center>
    );
  }
  
  export default DisplayUsers;