import { Box, Grid, GridItem, Center, Button } from "@chakra-ui/react";
import SearchUsers from "../Components/SearchUsers";
import DisplayUsers from "../Components/DisplayUsers";

function AddUsers({setUsers, users, setAllUsersAdded}) {

  function saveUsers () {
    window.localStorage.setItem("Users", JSON.stringify(users));
    setAllUsersAdded(true)
  }

  return (
    <Center flexDir="column" minH="80vh">
    <Grid m="5"
                templateColumns='repeat(2, 1fr)'
                gap={5}>
        <GridItem colSpan={{lg: 1, base: 2, md: 2, sm: 2}} rowSpan={1}>
          <SearchUsers users={users} setUsers={setUsers} />
        </GridItem>
        <GridItem colSpan={{lg: 1, base: 2, md: 2, sm: 2}} rowSpan={1}>
          <DisplayUsers setUsers={setUsers} users={users} />
        </GridItem>
    </Grid>
    <Box>
      <Button isDisabled={users.length === 0} onClick={() => saveUsers()}>Continue</Button>
    </Box>
    </Center>
  );
}

export default AddUsers;