import { Center, Heading, Stack, Flex, Spacer, Text, Spinner, useColorMode } from '@chakra-ui/react';
import StageDisplay from '../Components/StageDisplay';
import Schedule from '../Components/Schedule';
import { useEffect, useState } from 'react';
import Navbar from '../Components/Navbar';
import AddUsers from './AddUsers';

function Home({currentStage, setAllUsersAdded}) {

  return (
    <>
        <Navbar />
        <StageDisplay currentStage={currentStage} />
        <Schedule setAllUsersAdded={setAllUsersAdded} currentStage={currentStage} />
    </>
  );
}

export default Home;