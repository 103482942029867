import { extendTheme } from '@chakra-ui/react'

const config = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
}

const colors = {
  1: "#ff9189",
  2: "#ff7066",
  3: "#ff4e42",
  4: "#ff2d1e",
  5: "#f91000",
  6: "#d60e00",
  7: "#b20b00",
  8: "#8e0900"
}

const Button = {
  variants: {
    primary: {
      mb:"1rem",
      mt:"1rem",
      bg:"teal",
      color:"white",
      _hover: { 
        bg: "teal.700",
        transition: '0.3s'}
    }
  },
  defaultProps: {
    variant: 'primary'
  }
}

const theme = extendTheme({
  config,
  fonts: {
    heading: 'sans-serif',
    body: 'sans-serif',
  },
  textStyles: {
},
styles: {
  global: (props) => ({
    'html, body': {
      color: props.colorMode === 'dark' ? 'white' : 'gray.600',
      bg: props.colorMode === 'dark' ? '#181818' : 'white'
    },
  }),
},
components: {
  Button
},
colors
})

export default theme