import { Heading, Stack, Center, Text, Box, Spacer, Img, Link, Button, FormControl, FormLabel, Switch } from '@chakra-ui/react';

function Banner() {

  return (
    <Center color="white" flexDir="column" top="0px" bg="teal" position="sticky" zIndex="docked" h="50px" w="100%">
        <Text>Please note this app is still in development</Text>
        <Text>Report bugs to @brndnvntr on X</Text>
    </Center>
  );
}

export default Banner;