import { Heading, Stack, Flex, Text, Box, Spacer } from '@chakra-ui/react';
import DateTimeConverter from './DateTimeConverter';

function StageDisplay({currentStage}) {

  return (
    <Flex
    as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      w="100%"
    zIndex="200"
    boxShadow="xl"
    borderRadius="5px">
          <Flex justify="start">
            <Stack m="2" p="4" bgColor="blackAlpha.200">
                <Text>Current Eskom Stage: </Text>
                <Heading>{currentStage.status.eskom.stage}</Heading>
            </Stack>
            <Stack m="2" p="4" bgColor="blackAlpha.200">
                <Text>Next Eskom Stage: </Text>
                <Heading>{currentStage.status.eskom.next_stages[0].stage}</Heading>
                <DateTimeConverter nextStage={currentStage.status.eskom.next_stages[0].stage_start_timestamp} />
            </Stack>
          </Flex>
          <Spacer />
          <Flex justify="flex-end">
            <Stack m="2" p="4" bgColor="blackAlpha.200">
                <Text>Current Cape Town Stage: </Text>
                <Heading>{currentStage.status.capetown.stage}</Heading>
              </Stack>
              <Stack m="2" p="4" bgColor="blackAlpha.200">
                <Text>Next Cape Town Stage: </Text>
                <Heading>{currentStage.status.capetown.next_stages[0].stage}</Heading>
                <DateTimeConverter nextStage={currentStage.status.capetown.next_stages[0].stage_start_timestamp} />
              </Stack>
          </Flex>
    </Flex>
  );
}

export default StageDisplay;